// nav
const buttonNav = document.getElementById("buttonNav");
const mainNav = document.getElementById("mainNav");
const overlayNav = document.getElementById("overlayNav");

buttonNav.addEventListener("click", (e) => {
	mainNav.classList.remove("t-translate-x-[-225px]");
	overlayNav.classList.add("t-fixed", "t-opacity-75");
	overlayNav.classList.remove("t-opacity-0");
	document.body.classList.add("t-overflow-hidden");
});

overlayNav.addEventListener("click", (e) => {
	mainNav.classList.add("t-translate-x-[-225px]");
	overlayNav.classList.remove("t-fixed", "t-opacity-75");
	overlayNav.classList.add("t-opacity-0");
});

// NavAccordion
const jsNavAccordion = document.querySelectorAll(".jsNavAccordion");

jsNavAccordion.forEach((navAccordion) => {
	const btn = navAccordion.querySelector(".jsNavAccordion-btn");

	btn.addEventListener("click", () => {
		btn.classList.toggle("t-opacity-50");
		btn.classList.toggle("t-opacity-100");

		const icon = navAccordion.querySelector(".jsNavAccordion-icon");
		icon.classList.toggle("t-rotate-90");
		icon.classList.toggle("t-rotate-0");

		const parent = navAccordion.querySelector(".jsNavAccordion-parent");
		parent.classList.toggle("t-hidden");
		parent.classList.toggle("t-block");
	});
});
